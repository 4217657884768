import "./style.scss";

import { createElement, Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";

import { graphql } from "gatsby";
import AccentContainer from "../../components/Container/_accent";
import AnchorLink from "../../components/Links/anchorLink";
import ArrowToRight from "../../components/Icons/ArrowToRight";
import Container from "../../components/Container";
import GeneralForm from "../../components/Forms/_main";
import HMQ from "../../components/HaveMoreQuestionsBlock";
import Heading from "../../components/Heading";
import Layout from "../../layouts";
import Link from "../../components/Links/LinkOnPage";
import List from "../../components/List/index_";
import SupportIcon from "../../components/Icons/Support";
import Title from "../../components/Title";
import { createClassName } from "../../helpers";

const cn = createClassName("support");

const itemLink = (elem) => (
  <div className={cn("documentation", { sublist: "link" })}>
    <ArrowToRight
      color="primary-main"
      className={cn("documentation", { sublist: "link-arrow" })}
    />
    <Link
      href={elem.link}
      color="default"
      className={cn("documentation", { sublist: "link-text" })}
    >
      <Title level={5} className={cn("documentation", { sublist: "link-text" })} marker={elem.marker}>
        {elem.product}
      </Title>
    </Link>
  </div>
);

const documentationItem = (list, divElement = false) => list.map((item, index) => createElement(
  divElement ? "div" : "li",
  {
    key: index,
    className: `${cn("documentation", { sublist: "list" })}`,
  },
  itemLink(item),
));

const documentationList = (list) => (
  <ul className={cn("documentation", { sublist: true })}>
    {list.title && (
      <Title level={5} className={cn("documentation", { sublist: "title" })}>
        {list.title}
      </Title>
    )}
    {documentationItem(list.listing)}
  </ul>
);

const documentation = (list) => list.map((item, index) => (
  <Fragment key={index}>{documentationList(item)}</Fragment>
));

const shortLinks = (list) => list.map((item, index) => (
  <li key={index} className={cn("short-links", { link: true })}>
    <Link
      href={item.link}
      color="default"
      className={cn("short-links", { link: "wrap" })}
    >
      <div className={cn("short-links", { link: "icon" })}>
        <SupportIcon
          icon={item.icon}
          alt={item.alt}
          className={cn("short-links", { link: "icon-img" })}
        />
      </div>
      <div className={cn("short-links", { link: "info" })}>
        <Title level={4} weight="medium" align="center">
          {item.title}
        </Title>
        <p className={cn("short-links", { link: "info-description" })}>
          {item.description}
        </p>
      </div>
    </Link>
  </li>
));

const Support = (data) => {
  const { frontmatter } = data.data.markdownRemark;
  const { t } = useTranslation("strings");

  return (
    <Layout {...frontmatter.seo[0]}>
      <section aria-label="banner">
        <AccentContainer>
          <div className={cn("banner", { wrap: true })}>
            <Heading
              level={1}
              fontLevel={1}
              color="white"
              weight="light"
              align="center"
            >
              {frontmatter.bannerTitle}
            </Heading>
          </div>
        </AccentContainer>
      </section>
      <section aria-label="short links">
        <Container>
          <ul className={cn("short-links", { wrap: true })}>
            {shortLinks(frontmatter.shortLinksList)}
          </ul>
        </Container>
      </section>
      <section aria-label="Documentation all toolkits" id="docs-desktop">
        <Container>
          <Heading
            level={2}
            align="center"
            weight="light"
            className={cn("documentation", { block: "title-h2" })}
          >
            {frontmatter.heading}
          </Heading>
          <Title level={5} align="center">
            {frontmatter.title}
          </Title>
          <Heading
            level={3}
            fontLevel={4}
            className={cn("documentation", { block: "title" })}
          >
            {frontmatter.featuresList.title}
          </Heading>
          <List
            isVertical
            listClassName={cn("documentation", { list: true, feature: "list" })}
            itemClassName={cn("documentation", { list: "item" })}
          >
            {documentation(frontmatter.featuresList.list)}
          </List>
          <Heading
            level={3}
            fontLevel={4}
            className={cn("documentation", {
              block: "title",
              genInformation: "title",
            })}
          >
            {frontmatter.genInformationList.title}
          </Heading>
          <List
            listClassName={cn("documentation", { list: true })}
            itemClassName={cn("documentation", { list: "item" })}
          >
            {documentation(frontmatter.genInformationList.list)}
          </List>
        </Container>
      </section>
      <section ari-label="Have more questions">
        <div className="container-info-block">
          <HMQ
            className={cn({ hmq: true })}
            title={t("hmq.title-support")}
            description={(
              <Trans i18nKey="strings:hmq.description-support">
                Find clear answers to
                <Link href="/support/faq/" color="primary-main">
                  frequent questions
                </Link>
                or
                <AnchorLink href="contact-support" color="primary-main">
                  contact us
                </AnchorLink>
                .
              </Trans>
            )}
          />
        </div>
      </section>
      {/* <section aria-label="Documentation online apps" id="docs-online-apps">
        <Container>
          <Heading
            level={2}
            align="center"
            weight="light"
            className={cn("documentation", { block: "title-h2" })}
          >
            {frontmatter.headingOnlineApps}
          </Heading>
          <Title level={5} align="center">
            {frontmatter.titleOnlineApps}
          </Title>
          <div className={cn("documentation", { block: "last" })}>
            <div className={cn("documentation", { online: "block" })}>
              <Heading
                level={3}
                fontLevel={4}
                className={cn("documentation", { block: "title" })}
              >
                {frontmatter.onlineAppsList.title}
              </Heading>
              <List
                isVertical
                listClassName={cn("documentation", {
                  list: true,
                  sublist: "item",
                })}
              >
                {documentation(frontmatter.onlineAppsList.list)}
              </List>
            </div>
            <div className={cn("documentation", { online: "block" })}>
              <Heading
                level={3}
                fontLevel={4}
                className={cn("documentation", { block: "title" })}
              >
                {frontmatter.onlineAppsInformationList.title}
              </Heading>
              <List
                isVertical
                listClassName={cn("documentation", {
                  list: true,
                  sublist: "item",
                })}
              >
                {documentation(frontmatter.onlineAppsInformationList.list)}
              </List>
            </div>
          </div>
        </Container>
      </section> */}
      <section aria-label="Contact support team" id="contact-support">
        <div className={cn("contact", { wrap: true })}>
          <Container>
            <GeneralForm
              classNameForm={cn("contact", { form: true })}
              heading={frontmatter.form.title}
              title={frontmatter.form.subtitle}
              alignTitle="center"
              alignSubtitle="center"
              textareaPlaceholder={frontmatter.form.placeholder}
              classNameSubmit={cn("contact", { form: "submit" })}
            />
          </Container>
        </div>
      </section>
      <div className={cn("line")} />
    </Layout>
  );
};

export default Support;

export const query = graphql`
  query getSupport($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      frontmatter {
        bannerTitle
        heading
        title
        headingOnlineApps
        titleOnlineApps
        seo {
          description
          link
          title
        }
        shortLinksList {
          alt
          description
          icon
          link
          title
        }
        featuresList {
          title
          list {
            title
            listing {
              link
              product
              marker
            }
          }
        }
        genInformationList {
          title
          list {
            title
            listing {
              link
              product
            }
          }
        }
        onlineAppsList {
          title
          list {
            title
            listing {
              link
              product
            }
          }
        }
        onlineAppsInformationList {
          title
          list {
            title
            listing {
              link
              product
            }
          }
        }
        form {
          title
          subtitle
          placeholder
        }
      }
    }
  }
`;
